import './index.scss'
import React, { useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../layouts/default'
import Page from '../../components/Page'
import usePageHeader from '../../hooks/usePageHeader'
import { H1, H2 } from '../../styles'
import Container from '../../components/Container'
import { Tab, Tabs } from '../../components/Tabs'
import { OnshapeInstructions } from './onshape-instructions'
import { MaybeAnswer } from './maybe-answer'
import controller from './controller.webp'
import seat from './seat.webp'
import toy_plane from './toy plane.webp'

export const BetaInstructions = () => {
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()
  const [active, setActive] = useState<undefined | number>(undefined)

  return (
    <Layout>
      <SEO title='Instructions for new users' />
      <Page className='instructions' as='main' pageHeaderHeight={pageHeaderHeight} columnWidth={600}>
        <Page.Header as='header' columnWidth={600} ref={pageHeaderRef}>
          <Page.Subtitle>For new users</Page.Subtitle>
          <H1 as={H2}>Instructions</H1>
        </Page.Header>
        <Container className='container' columnWidth={600} as='section'>
          <p className='header'>There are multiple ways to access Phi. Let's find out the one for you!</p>
          <p className='question'>Do you own an Onshape account?</p>

          <Tabs hoverSelect={false} active={active} onActive={setActive}>
              <Tab name='No'>
                Visit <a href='https://app.phi3d.com' target='_blank'>app.phi3d.com</a>, register and enjoy using Phi!
              </Tab>

              <Tab name='No, but maybe I want one'>
                <MaybeAnswer/>

                <OnshapeInstructions/>
              </Tab>

              <Tab name='Yes'>
                <OnshapeInstructions/>
              </Tab>
          </Tabs>

          <figure>
            <img src={toy_plane}/>
            <img src={seat} className='seat'/>
            <img src={controller}/>
          </figure>

          <p>Still confused? Please
            <a href='https://phi3d.com/contact' target='_blank'> contact us!</a>
          </p>
        </Container>
      </Page>
    </Layout>
  )
}
