import React from "react"
import "./VimeoVideo.scss"

type AspectRatio = '16:9';

const split_aspect_ratio = (x: AspectRatio) => x.split(':') as [string, string];

type Props = {
    id: number;
    aspectRatio?: undefined | AspectRatio;
    title: undefined | string;
}

const VimeoVideo = ({ id, aspectRatio = '16:9', title }: Props) => {
    const [w, h] = split_aspect_ratio(aspectRatio);
    if (!title)
        title = `Video ${id}`;

    return <div
        className="vm-video"
        style={{
            // @ts-ignore
            "--aspect-ratio-w": w,
            "--aspect-ratio-h": h
        }}
    >
        <iframe
            className="vm-video__video"
            width="100%"
            height="100%"
            title={title}
            src={`https://player.vimeo.com/video/${id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </div>;
}

export default VimeoVideo
