import React, { useEffect, useRef } from 'react';
import { classNames } from '../../utils/html';
import './index.scss';
import { scroll_vertically_by } from '../../utils/dom';

export type Props = {
    active: boolean;
    onClick: () => void;
    header: string | JSX.Element;
    children: string | JSX.Element | Array<string | JSX.Element>;
};

export default function Dropdown(props: Props) {
    const bodyRef = useRef<null | HTMLLIElement>(null)

    useEffect(() => {
        if (props.active && bodyRef)
            onBodyShow()
    }, [props.active])

    function onBodyShow() {
        const rect = bodyRef.current!.getBoundingClientRect()
        if (rect.top < 0) {
            bodyRef.current!.scrollIntoView()
            queueMicrotask(() => scroll_vertically_by(-120))
        }
    }

    return (<li ref={bodyRef} role='button' tabIndex={0} onClick={props.onClick} className='dropdown'>
        <h3>
            <span className={classNames({ indicator: true, active: props.active })} />
            <span className='text'>{props.header}</span>
        </h3>
        <div className={classNames({ body: true, hidden: !props.active })}>
            {props.children}
        </div>
    </li>);
}
