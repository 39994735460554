import './maybe-answer.scss'
import React from 'react'

export function MaybeAnswer() {
    return <div className='maybe-answer'>
        <h3>Register to Onshape </h3>

        <p>First sign up to the <a href='https://www.onshape.com/en/sign-up' target='_blank'>Onshape plan</a> that suits you.</p>
    </div>
}
