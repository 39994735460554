import './index.scss';
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import Button from "../Button"
import { classNames } from '../../utils/html'

const STabs = styled.div``
const TabsHeader = styled.header`
  overflow-x: auto;
`
const TabsMenu = styled.nav`
  display: grid;
  grid-auto-flow: column;
  gap: 0.8rem;
`

const TabsContent = styled.div`
  margin-top: 3.2rem;
`

export const Tab = styled.div``

type Props = {
  children: Array<JSX.Element>;
  hoverSelect?: undefined | boolean;
  active: number | undefined;
  onActive: (x: number) => void;
}

export const Tabs = ({
  children,
  hoverSelect = true,
  active,
  onActive,
}: Props) => {
  return (
    <STabs>
      <TabsHeader className='tabs-header'>
        <TabsMenu>
          {children.map((tab, i) => (
            <TabButton
              key={`button-${i}`}
              name={tab.props.name}
              index={i}
              setSelectedTab={onActive}
              isSelected={active === i}
              hoverSelect={hoverSelect}
            />
          ))}
        </TabsMenu>
      </TabsHeader>
      <TabsContent>{active === undefined ? active : children[active]}</TabsContent>
    </STabs>
  )
}

// export const Tab = ({ children }) => {
//   return <Tab>{children}</Tab>
// }

const TabButton = ({ name, index, setSelectedTab, isSelected, hoverSelect }) => {
  const change_tab = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <Button
      variant='ghost'
      extraClass={classNames({
        'tabs-menu-item': true,
        selected: isSelected,
      })}
      onClick={change_tab}
      onMouseOver={hoverSelect ? change_tab : undefined}
    >
      {name}
    </Button>
  )
}
