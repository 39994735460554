import './onshape-instructions.scss'
import React, { useState } from 'react'
import Dropdown from '../../components/dropdown'
import VimeoVideo from '../../components/VimeoVideo'

type Device =
    'computer'
    | 'tablet'

export function OnshapeInstructions() {
    const [dropdown, setDropdown] = useState<Device | undefined>(undefined)
    return <div className='onshape-instructions'>
        <p>Great! With an Onshape account, you can use Phi in its full functionality.</p>

        <p className='question'>Register to Phi</p>

        <ol>
            <li>
                Visit <a href='https://appstore.onshape.com/apps/Design%20&%20Documentation/BI4NF3BZAEQXBXOERCYO6NSHJ5Z62TB6ZI472VA=/description' target='_blank'>
                    Phi in the Onshape App Store
                </a>
            </li>

            <li>
                Subscribe to your preferred plan
            </li>
        </ol>
        {/* + a gif demonstrating the above */}

        <p className='question'>Run Phi</p>

        <p>What device are you on?</p>

        <Dropdown
            active={dropdown === 'computer'}
            onClick={() => setDropdown('computer')}
            header={'Computer'}
        >
            <p>Phi can be used as an Integrated Onshape Application:</p>

            <ol>
                <li>On <a href='https://cad.onshape.com/' target='_blank'>cad.onshape.com</a> create a new document or open an existing one</li>
                <li>Once in the document, click on the + sign at the bottom left</li>
                <li>
                    <div className='tall'>In the <code>+</code> menu, find <code>Applications</code>, and select <code>Phi</code></div>

                    <div>If you can't locate Phi in the list, please reload the browser tab.</div>
                </li>
            </ol>

            <p>Phi will launch in a new Onshape tab.</p>

            <VimeoVideo id={832325694} title='Computer instructions'/>
        </Dropdown>

        <Dropdown
            active={dropdown === 'tablet'}
            onClick={() => setDropdown('tablet')}
            header={'Tablet'}
        >
            <p>Phi can be used as a Connected Onshape Application:</p>

            <ol>
                <li>Visit <a href='https://app.phi3d.com' target='_blank'>app.phi3d.com</a> on your browser</li>
                <li>Log in with your Onshape account</li>
            </ol>
            {/* + a gif demonstrating the above */}
        </Dropdown>
    </div>
}
